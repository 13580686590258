import React from 'react'
import { Box, PrimaryButton, Flex, InjectedModalProps, LinkExternal, Message, Text } from 'uikit'
import { useWeb3React } from '@web3-react/core'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { getBscScanLink } from 'utils'
import { getFullDisplayBalance, formatBigNumber } from 'utils/formatBalance'
import tokens from 'config/constants/tokens'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance } = useGetBnbBalance()
  const { balance: cakeBalance } = useTokenBalance(tokens.cake.address)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss()
    logout()
  }

  return (
    <>
      <Text color="#392953" fontSize="12px" lineHeight="13px" mb="8px">
        Your Address
      </Text>

      <CopyAddress account={account} mb="8px" />

      {hasLowBnbBalance && (
        <Message title="BNB Balance Low" mb="16px">
          <Box>
            <Text fontSize="14px" lineHeight="15px" color="##392953">
              You need BNB for transaction fees.
            </Text>
          </Box>
        </Message>
      )}

      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text color="#392953" fontSize="14px" lineHeight="15px">
          BNB Balance
        </Text>
        {balance && (
          <Text fontSize="14px" lineHeight="15px">
            {formatBigNumber(balance, 6)}
          </Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="16px">
        <Text fontSize="14px" lineHeight="15px" color="#392953">
          CAKE Balance
        </Text>
        {cakeBalance && (
          <Text fontSize="14px" lineHeight="15px">
            {getFullDisplayBalance(cakeBalance, 18, 3)}
          </Text>
        )}
      </Flex>

      <Flex alignItems="center" justifyContent="center" mb="8px">
        <LinkExternal color="#392953" href={getBscScanLink(account, 'address')}>
          {t('View on BscScan')}
        </LinkExternal>
      </Flex>

      <PrimaryButton scale="lg" color="red" onClick={handleLogout}>
        {t('Disconnect')}
      </PrimaryButton>
    </>
  )
}

export default WalletInfo
