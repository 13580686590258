import React, { FC } from 'react'
import styled from 'styled-components'
import { getBeforeBg, getBeforeClipPath, getSpanClipPath, sides, getSpanBg, palette } from '../Button/styled'

export const Wrapper = styled.div<any>`
  display: block;
  outline: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  height: min-content;
  width: 94px;

  &:hover,
  &:focus {
    input {
      background-color: ${({ color }) => palette[color]?.hover};
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 40px;
    left: 0;
    bottom: 0;

    border: ${({ color }) => `2px solid ${palette[color]?.darkBg}`};
    border-top: none;
    background: ${({ color }) => getBeforeBg(color, 'md')};
    background-repeat: no-repeat;
    clip-path: ${getBeforeClipPath(sides.md)};
  }
`

export const Input = styled.input<any>`
  transition: all 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fefefe;
  background: ${({ color }) => getSpanBg(color, 'md')};
  background-repeat: no-repeat;
  font-weight: 400;

  clip-path: ${getSpanClipPath(sides.md)};

  height: 39px;
  padding: 6px 16px 9px;
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-bottom: 14px;
  font-size: 20px;
  line-height: 22px;

  text-align: center;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #fefefe;
  }
`

export const ButtonInput: FC<any> = ({ color = 'green', ...rest }) => (
  <Wrapper as="div" color={color}>
    <Input {...{ color, ...rest }} onClick={(e) => e.preventDefault()} />
  </Wrapper>
)
