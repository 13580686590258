import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Trade, TradeType } from '@pancakeswap/sdk'
import { PrimaryButton, Text } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { Field } from 'state/swap/actions'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import TradePrice from './TradePrice'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SwapCallbackError } from './styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin: 18px 0 16px;
`

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const { currencies } = useDerivedSwapInfo()

  const { t } = useTranslation()
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade],
  )
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <SwapModalFooterContainer>
        <RowBetween align="center">
          <Text fontSize="14px" color="#392953">
            Price
          </Text>
          <TradePrice
            price={trade?.executionPrice}
            showInverted={showInverted}
            setShowInverted={setShowInverted}
            inputCurrency={currencies[Field.INPUT]}
            outputCurrency={currencies[Field.OUTPUT]}
            fontSize="14px"
          />
        </RowBetween>

        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="#392953">
              {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
            </Text>
            <QuestionHelper
              text={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
              )}
              ml="4px"
            />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px" color="#392953">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween mt="8px">
          <RowFixed>
            <Text fontSize="14px" color="#392953">
              Price Impact
            </Text>
            <QuestionHelper
              text={t('The difference between the market price and your price due to trade size.')}
              ml="4px"
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween mt="8px">
          <RowFixed>
            <Text fontSize="14px" color="#392953">
              Liquidity Provider Fee
            </Text>
            <QuestionHelper
              text={
                <>
                  <Text fontSize="12px" mb="12px">
                    {t('For each trade a %amount% fee is paid', { amount: '0.25%' })}
                  </Text>
                  <Text fontSize="12px">- {t('%amount% to LP token holders', { amount: '0.17%' })}</Text>
                  <Text fontSize="12px">- {t('%amount% to the Treasury', { amount: '0.03%' })}</Text>
                  <Text fontSize="12px">- {t('%amount% towards CAKE buyback and burn', { amount: '0.05%' })}</Text>
                </>
              }
              ml="4px"
            />
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
      </SwapModalFooterContainer>

      <AutoRow>
        <PrimaryButton scale="lg" onClick={onConfirm} disabled={disabledConfirm} id="confirm-swap-or-send">
          {severity > 2 ? t('Swap Anyway') : t('Confirm Swap')}
        </PrimaryButton>

        {swapErrorMessage ? <SwapCallbackError mt="8px" error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
