import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M6 7V18H18V7H6Z" fill="white" />
      <path d="M8 12H7V11H8V12Z" fill="black" />
      <path d="M10 12H9V11H10V12Z" fill="black" />
      <path d="M12 12H11V11H12V12Z" fill="black" />
      <path d="M14 12H13V11H14V12Z" fill="black" />
      <path d="M6 6V7H9V8H10V7H14V8H15V7H18V6H15V5H14V6H10V5H9V6H6Z" fill="black" />
      <path d="M5 18H6V10H18V18H19V7H18V9H6V7H5V18Z" fill="black" />
      <path d="M6 18V19H18V18H6Z" fill="black" />
    </Svg>
  )
}

export default Icon
