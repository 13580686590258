export const getClipPath = ({ sides, type }: { sides: any; type: string }) => {
  const { a, b, a100, b100 } = sides.x
  const { long, short, long100, short100 } = sides.y

  switch (type) {
    case 'MODAL':
      return `
        polygon(
          0 ${long},
          ${a} ${long},
          ${a} ${short},
          ${b} ${short},
          ${b} 0,

          ${b100} 0,
          ${b100} ${short},
          ${a100} ${short},
          ${a100} ${long},
          100% ${long},

          100% ${long100},
          ${a100} ${long100},
          ${a100} ${short100},
          ${b100} ${short100},
          ${b100} 100%,

          ${b} 100%,
          ${b} ${short100},
          ${a} ${short100},
          ${a} ${long100},
          0 ${long100}
      )
    `
    default:
      return `
        polygon(
          0 0,
          100% 0,
          100% 100%,
          0 100%
        )
      `
  }
}
