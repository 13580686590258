import React, { useState } from 'react'
import { Token } from '@pancakeswap/sdk'
import { ButtonMenu, PrimaryButton } from 'uikit'
import { TokenList } from '@uniswap/token-lists'
import ManageLists from './ManageLists'
import ManageTokens from './ManageTokens'
import { CurrencyModalView } from './types'

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
}) {
  const [showLists, setShowLists] = useState(true)

  return (
    <>
      <ButtonMenu
        mb="16px"
        fullWidth
        gap="16px"
        variant="subtle"
        onItemClick={() => setShowLists((prev) => !prev)}
        activeIndex={showLists ? 0 : 1}
      >
        <PrimaryButton>Lists</PrimaryButton>
        <PrimaryButton>Tokens</PrimaryButton>
      </ButtonMenu>

      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : (
        <ManageTokens setModalView={setModalView} setImportToken={setImportToken} />
      )}
    </>
  )
}
