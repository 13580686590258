import React, { useState } from 'react'
import {
  ButtonMenu,
  ModalCloseButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
} from 'uikit'
import { parseUnits } from 'ethers/lib/utils'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <ModalContainer minWidth="480px">
      <ModalHeader>
        <ModalTitle>Your Wallet</ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>

      <ModalBody>
        <ButtonMenu mb="16px" fullWidth gap="16px" variant="subtle" onItemClick={handleClick} activeIndex={view}>
          <PrimaryButton>Wallet</PrimaryButton>
          <PrimaryButton>Transactions</PrimaryButton>
        </ButtonMenu>

        {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
        {view === WalletView.TRANSACTIONS && <WalletTransactions />}
      </ModalBody>
    </ModalContainer>
  )
}

export default WalletModal
