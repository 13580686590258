import styled from 'styled-components'

const clipPath = `polygon(
  0 1.3%,
  1.3% 1.3%,
  1.3% 0,
  98.7% 0,
  98.7% 1.3%,
  100% 1.3%,
  100% 98.3%,
  98.7% 98.3%,
  98.7% 100%,
  1.3% 100%,
  1.3% 98.3%,
  0 98.3%
)`

export const BodyWrapper = styled.div`
  z-index: 1;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 13px;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.card.shadowColor};
    clip-path: ${clipPath};
  }
`

export const ContentWrapper = styled.div<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => minWidth || '466px'};
  clip-path: ${clipPath};
  padding: 35px 35px 35px 34px;
  background: ${({ theme }) => `
    // dark
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat 0 0/14px 100%, 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat 0 0/100% 15px, 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat right 0 top 0/15px 100%, 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat left 0 bottom 0/100% 15px, 

    // dark corners
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat 14px 15px/1.5% 1.3%, 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat right 14px top 15px/calc(1.3% + 1px) 1.3%, 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat right 14px bottom 14px/calc(1.3% + 1px) calc(1.7% + 1px), 
    linear-gradient(${theme.card.darkBorder},${theme.card.darkBorder}) no-repeat left 14px bottom 14px/1.5% calc(1.7% + 1px), 
    
    // violet
    linear-gradient(${theme.card.violetBorder},${theme.card.violetBorder}) no-repeat right 15px top 15px/10px 100%, 
    linear-gradient(${theme.card.violetBorder},${theme.card.violetBorder}) no-repeat right 15px bottom 15px/100% 10px, 
    linear-gradient(${theme.card.violetBorder},${theme.card.violetBorder}) no-repeat right 24px bottom 24px/calc(1.3% + 1px) calc(1.7% + 1px), 
    
    // white
    linear-gradient(#fff,#fff) no-repeat 14px 15px/10px 100%, 
    linear-gradient(#fff,#fff) no-repeat 14px 15px/100% 10px, 
    linear-gradient(#fff,#fff) no-repeat 24px 25px/1.5% 1.3%,  
    
    ${theme.card.background}
  `};
`
