import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from 'uikit'
import { menuLinks } from './config/config'
import UserMenu from './UserMenu'
import { getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { pathname } = useLocation()

  const activeSubMenuItem = getActiveSubMenuItem({ menuLinks, pathname })

  return <UikitMenu userMenu={<UserMenu />} subLinks={menuLinks} activeSubItem={activeSubMenuItem?.href} {...props} />
}

export default Menu
