import React from 'react'
import styled from 'styled-components'
import { Grid } from 'components/Layout/Grid'
import { PrimaryButton } from '../Button'
import { ToggleProps } from './types'

const Wrapper = styled(Grid)`
  grid-template-columns: repeat(2, 94px);
`

export const Toggle: React.FC<ToggleProps> = ({ checked, handleSelect }) => (
  <Wrapper gap="8px">
    <PrimaryButton onClick={() => handleSelect(true)} isActive={checked}>
      ON
    </PrimaryButton>

    <PrimaryButton color="red" onClick={() => handleSelect(false)} isActive={!checked}>
      OFF
    </PrimaryButton>
  </Wrapper>
)
