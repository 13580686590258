import styled from 'styled-components'
import { space, variant } from 'styled-system'

export const palette = {
  green: {
    dark: '#3a9138',
    light: '#61df5f',
    darkBg: '#1e341e',
    lightDis: '#6e966d',
    darktDis: '#355035',
    hover: '#24bd21',
    spanBg: '#26cc23',
    spanBgDis: '#5b835a',
    beforeBg: '#259b22',
    beforeBgDis: '#355A34',
  },
  orange: {
    darkBg: '#1e341e',
    dark: 'rgba(226, 118, 37, 0.46)',
    light: '#F7933A',
    hover: '#f07a18',
    spanBg: '#F5841F',
    beforeBg: '#E27625',

    spanBgDis: '#d17c32',
    beforeBgDis: '#b3652b',
    lightDis: '#d9863d',
    darktDis: '#a15d22',
  },

  violet: {
    darkBg: '#1E341E',
    light: '#D866FF',
    dark: '#8019A4',

    hover: '#9534B9',
    spanBg: '#C743F5',
    beforeBg: '#9532B8',

    spanBgDis: '#5b835a',
    beforeBgDis: '#355A34',
    lightDis: '#6e966d',
    darktDis: '#355035',
  },

  red: {
    darkBg: '#1e341e',
    dark: 'rgba(181, 35, 35, 0.46)',
    light: '#FF7A7A',
    hover: '#e54044',
    spanBg: '#F44545',
    beforeBg: '#B52323',

    spanBgDis: '#c25151',
    beforeBgDis: '#943b3b',
    lightDis: '#e88484',
    darktDis: '#a14a4a',
  },
}

export const getSpanBg = (type, scale) => {
  const { dark, light, spanBg } = palette[type]
  const lightLG = `linear-gradient(${light} 0, ${light} 100%)`
  const darkLG = `linear-gradient(${dark} 0, ${dark} 100%)`

  const bg = {
    sm: `
    ${darkLG} right 4px top 0 / 1px 1px,
    ${darkLG} right 2px top 2px / 1px 1px,
    ${darkLG} right 0 top 4px /1px calc(100% - 8px),
    ${darkLG} 3px bottom /calc(100% - 6px) 1px,
    ${darkLG} right bottom 1px /5px 2px,
    ${darkLG} right bottom 3px /3px 2px,
    ${darkLG} left 1px bottom 2px /2px 1px,
    ${lightLG} 4px top / calc(100% - 8px) 2px,
    ${lightLG} 2px 2px / 4px 2px,
    ${lightLG} 2px 4px / 2px 2px,
    ${lightLG} 0 4px / 2px calc(100% - 4px),
    ${spanBg}
  `,
    md: `
    ${darkLG} 4px bottom /calc(100% - 8px) 2px,
    ${darkLG} left 2px bottom 2px /4px 2px,
    ${darkLG} right 2px bottom 2px /4px 2px,
    ${lightLG} right 2px top 2px /2px 2px,
    ${lightLG} 0 6px /2px calc(100% - 12px),
    ${lightLG} 2px 4px /2px 4px,
    ${lightLG} 2px 2px /6px 2px,
    ${lightLG} 4px 0 /calc(100% - 8px) 2px,
    ${spanBg}
    `,
    lg: `
    ${lightLG} 8px top / calc(100% - 16px) 3px, ${lightLG} 3px 2px / 8px 4px,
    ${lightLG} 3px 5px / 3px 3px, ${lightLG} 0 8px / 6px 3px, ${lightLG} 0 9px / 3px calc(100% - 20px),
    ${lightLG} right 3px top 3px / 5px 2px, ${darkLG} left bottom 8px / 3px 3px,
    ${darkLG} left 3px bottom 3px / 5px 3px, ${darkLG} left 8px bottom / calc(100% - 16px) 3px,
    ${darkLG} right bottom 8px / 3px 3px, ${darkLG} right 3px bottom 3px / 5px 3px,
    ${spanBg} ${darkLG} right 6px bottom / 3px 3px
  `,
  }

  return bg[scale]
}

const getDisabledSpanBg = (type) => {
  const { lightDis, darktDis, spanBgDis } = palette[type]
  const lightDisLG = `linear-gradient(${lightDis} 0, ${lightDis} 100%)`
  const darktDisLG = `linear-gradient(${darktDis} 0, ${darktDis} 100%)`

  return `
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 39 46'%3E%3Cpath fill='%2399AE98' d='M24 4h3v3h-3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M27 1h3v3h-3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M30 4h3v3h-3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M33 1h3v3h-3zm3-1h3v1h-3zM21 1h3v3h-3zm3-1h3v1h-3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M21 7h3v3h-3zm-6 3h3v3h-3zm-3-3h3v3h-3zM9 7h3v3H9zM6 4h3v3H6z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M3 1h3v3H3zM0 0h3v1H0z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M18 10h3v3h-3zm3 3h3v3h-3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M18 16h3v3h-3zm-3 3h3v3h-3zm-3 3h3v3h-3zm3 3h3v3h-3zm3 3h3v3h-3z' opacity='.6'/%3E%3Cpath fill='%2399AE98' d='M15 31h3v3h-3zm-3 3h3v3h-3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M9 37h3v3H9z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M6 37h3v3H6z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M3 40h3v3H3zm-3 3h3v3H0z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M15 37h3v3h-3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M18 40h3v3h-3zm3 3h3v3h-3z' opacity='.2'/%3E%3C/svg%3E")
    11px center,
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 39 46'%3E%3Cpath fill='%2399AE98' d='M15 42h-3v-3h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M12 45H9v-3h3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M9 42H6v-3h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M6 45H3v-3h3zm-3 1H0v-1h3zm15-1h-3v-3h3zm-3 1h-3v-1h3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M18 39h-3v-3h3zm6-3h-3v-3h3zm3 3h-3v-3h3zm3 0h-3v-3h3zm3 3h-3v-3h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M36 45h-3v-3h3zm3 1h-3v-1h3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M21 36h-3v-3h3zm-3-3h-3v-3h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M21 30h-3v-3h3zm3-3h-3v-3h3zm3-3h-3v-3h3zm-3-3h-3v-3h3zm-3-3h-3v-3h3z' opacity='.6'/%3E%3Cpath fill='%2399AE98' d='M24 15h-3v-3h3zm3-3h-3V9h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M30 9h-3V6h3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M33 9h-3V6h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M36 6h-3V3h3zm3-3h-3V0h3z' opacity='.2'/%3E%3Cpath fill='%2399AE98' d='M24 9h-3V6h3z' opacity='.44'/%3E%3Cpath fill='%2399AE98' d='M21 6h-3V3h3zm-3-3h-3V0h3z' opacity='.2'/%3E%3C/svg%3E")
    right 8px center,
    ${lightDisLG} 8px top / calc(100% - 16px) 3px, ${lightDisLG} 3px 2px / 8px 4px,
    ${lightDisLG} 3px 5px / 3px 3px, ${lightDisLG} 0 8px / 6px 3px, ${lightDisLG} 0 9px / 3px calc(100% - 20px),
    ${lightDisLG} right 3px top 3px / 5px 2px, ${darktDisLG} left bottom 8px / 3px 3px,
    ${darktDisLG} left 3px bottom 3px / 5px 3px, ${darktDisLG} left 8px bottom / calc(100% - 16px) 3px,
    ${darktDisLG} right bottom 8px / 3px 3px, ${darktDisLG} right 3px bottom 3px / 5px 3px,
    ${spanBgDis} ${darktDisLG} right 6px bottom / 3px 3px
  `
}

export const getBeforeBg = (type, scale) => {
  const { darkBg, beforeBg } = palette[type]
  const darkBgLG = `linear-gradient(${darkBg} 0, ${darkBg} 100%)`

  const bg = {
    sm: `
    ${darkBgLG} left bottom / 4px 2px, ${darkBgLG} left bottom 2px / 2px 2px, ${darkBgLG} right bottom / 4px 2px,
    ${darkBgLG} right bottom 2px / 2px 2px, ${darkBgLG} left top / 4px 2px, ${darkBgLG} left top 2px / 2px 2px,
    ${darkBgLG} right top / 4px 2px, ${beforeBg} ${darkBgLG} right top 2px / 2px 2px
  `,
    md: `
    ${darkBgLG} left bottom / 6px 2px, ${darkBgLG} left bottom 2px / 2px 4px, ${darkBgLG} right bottom / 6px 2px,
    ${darkBgLG} right bottom 2px / 2px 4px, ${darkBgLG} left top / 6px 2px, ${darkBgLG} left top 2px / 2px 4px,
    ${darkBgLG} left 2px top 2px / 2px 2px,
    ${darkBgLG} right 2px top 2px / 2px 2px,
    ${darkBgLG} left top 6px / 2px 2px,
    ${darkBgLG} right top 6px / 2px 2px,
    ${darkBgLG} right top / 6px 2px, ${beforeBg} ${darkBgLG} right top 2px / 2px 4px
  `,
    lg: `
    ${darkBgLG} left bottom / 8px 3px, ${darkBgLG} left bottom 3px / 3px 5px, ${darkBgLG} right bottom / 8px 3px,
    ${darkBgLG} right bottom 3px / 3px 5px, ${darkBgLG} left top / 8px 3px, ${darkBgLG} left top 3px / 3px 5px,
    ${darkBgLG} right top / 8px 3px, ${beforeBg} ${darkBgLG} right top 3px / 3px 5px
  `,
  }

  return bg[scale]
}

const getDisabledBeforeBg = (type) => {
  const { darkBg, beforeBgDis } = palette[type]
  const darkBgLG = `linear-gradient(${darkBg} 0, ${darkBg} 100%)`

  return `
    ${darkBgLG} left bottom / 8px 3px, ${darkBgLG} left bottom 3px / 3px 5px, ${darkBgLG} right bottom / 8px 3px,
    ${darkBgLG} right bottom 3px / 3px 5px, ${darkBgLG} left top / 8px 3px, ${darkBgLG} left top 3px / 3px 5px,
    ${darkBgLG} right top / 8px 3px, ${beforeBgDis} ${darkBgLG} right top 3px / 3px 5px
  `
}

export const sides = {
  sm: {
    a: '2px',
    b: '4px',
    a100: 'calc(100% - 2px)',
    b100: 'calc(100% - 4px)',
  },
  md: {
    a: '2px',
    b: '6px',
    a100: 'calc(100% - 2px)',
    b100: 'calc(100% - 6px)',
  },
  lg: {
    a: '3px',
    b: '8px',
    a100: 'calc(100% - 3px)',
    b100: 'calc(100% - 8px)',
  },
}

export const getBeforeClipPath = ({ a, b, a100, b100 }) => `polygon(
    0 ${b},
    ${a} ${b},
    ${a} ${a},
    ${b} ${a},
    ${b} 0,

    ${b100} 0,
    ${b100} ${a},
    ${a100} ${a},
    ${a100} ${b},
    100% ${b},

    100% ${b100},
    ${a100} ${b100},
    ${a100} ${a100},
    ${b100} ${a100},
    ${b100} 100%,

    ${b} 100%,
    ${b} ${a100},
    ${a} ${a100},
    ${a} ${b100},
    0 ${b100}
  )`

export const getSpanClipPath = ({ a, b, a100, b100 }) =>
  `polygon(
    0 ${b},
    ${a} ${b},
    ${a} ${a},
    ${b} ${a},
    ${b} 0,

    ${b100} 0,
    ${b100} ${a},
    ${a100} ${a},
    ${a100} ${b},
    100% ${b},

    100% ${b100},
    ${a100} ${b100},
    ${a100} ${a100},
    ${b100} ${a100},
    ${b100} 100%,

    ${b} 100%,
    ${b} ${a100},
    ${a} ${a100},
    ${a} ${b100},
    0 ${b100}
  )`

const wrapperScaleStyles = {
  sm: {
    width: '44px',
  },
  md: {
    width: '100%',
  },
  lg: {
    width: '100%',
    minHeight: '63px',
  },
}

const activeSpanScaleStyles = {
  sm: {
    transform: 'translateY(5px)',
  },
  md: {
    transform: 'translateY(8px)',
  },
  lg: {
    transform: 'translateY(11px)',
  },
}

const beforeScaleStyles = {
  sm: {
    height: '39px',
    borderWidth: '2px',
  },
  md: {
    height: '40px',
    borderWidth: '2px',
  },
  lg: {
    height: '53px',
    borderWidth: '3px',
  },
}

export const StyledButton = styled.button<{
  scale: string
  color: string
  maxWidth?: string
  isActive?: boolean
}>`
  display: block;
  outline: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  height: min-content;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};

  ${variant({
    prop: 'scale',
    variants: wrapperScaleStyles,
  })}

  &:hover,
  &:focus {
    span {
      background-color: ${({ color }) => palette[color]?.hover};
    }
  }

  &:active:not(:disabled) span {
    ${variant({
      prop: 'scale',
      variants: activeSpanScaleStyles,
    })}
  }

  span {
    ${({ isActive }) =>
      isActive &&
      variant({
        prop: 'scale',
        variants: activeSpanScaleStyles,
      })}
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ color }) => palette[color]?.darktDis};
    :before {
      background: ${({ color }) => getDisabledBeforeBg(color)};
      background-repeat: no-repeat;
    }
    span {
      background: ${({ color }) => getDisabledSpanBg(color)};
      background-repeat: no-repeat;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    left: 0;
    bottom: 0;
    transition: background-color 250ms;

    border: ${({ color }) => `3px solid ${palette[color]?.darkBg}`};
    border-top: none;
    background: ${({ color, scale }) => getBeforeBg(color, scale)};
    background-repeat: no-repeat;
    clip-path: ${({ scale }) => getBeforeClipPath(sides[scale])};

    ${variant({
      prop: 'scale',
      variants: beforeScaleStyles,
    })}
  }

  ${space}
`

const contentScaleStyles = {
  sm: {
    height: '36px',
    padding: '6px',

    width: 'calc(100% - 8px)',
    marginLeft: '4px',
    marginBottom: '8px',
  },
  md: {
    height: '39px',
    padding: '6px 16px 9px',
    width: 'calc(100% - 10px)',
    marginLeft: '5px',
    marginBottom: '14px',
    fontSize: '20px',
    lineHeight: '22px',
  },
  lg: {
    padding: '5px 16px 6px',
    fontSize: '28px',
    lineHeight: '31px',

    width: 'calc(100% - 12px)',
    height: '46px',
    marginLeft: '6px',
    marginBottom: '17px',
  },
}

export const ContentWrap = styled.span<{ scale: string; color: string }>`
  transition: margin 250ms, background-color 250ms, color 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background: ${({ color, scale }) => getSpanBg(color, scale)};
  background-repeat: no-repeat;
  font-weight: 400;
  clip-path: ${({ scale }) => getSpanClipPath(sides[scale])};

  ${variant({
    prop: 'scale',
    variants: contentScaleStyles,
  })}
`
