import styled from 'styled-components'
import { StyledMenuItemProps } from './types'

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;

  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === 'subMenu' &&
    `
      &:before{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};
`

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  display: flex;
  align-items: center;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.navActive : theme.colors.secondary)};
  font-size: 20px;
  line-height: 22px;
  transition: color 250ms;
  &:hover {
    color: ${({ theme }) => theme.colors.navHover};
  }
`

export default StyledMenuItem
