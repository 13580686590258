import styled from 'styled-components'

export const Grid = styled.div<{
  gap?: 'sm' | 'md' | 'lg' | string
  justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: ${({ gap }) =>
    (gap === 'sm' && '8px') || (gap === 'md' && '12px') || (gap === 'lg' && '24px') || gap};
  justify-content: ${({ justify }) => justify && justify};
`
