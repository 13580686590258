import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M17.6667 17.6667H6.33337V6.33334H17.6667V17.6667Z" fill="white" />
      <path d="M9.33337 10.3333H10.3334V11.3333H9.33337V10.3333Z" fill="black" />
      <path d="M9.33337 12.3333H10.3334V13.3333H9.33337V12.3333Z" fill="black" />
      <path d="M10.3334 9.33334H11.3334V10.3333H10.3334V9.33334Z" fill="black" />
      <path d="M10.3334 13.3333H11.3334V14.3333H10.3334V13.3333Z" fill="black" />
      <path d="M13.5 10.3333H14.5V11.3333H13.5V10.3333Z" fill="black" />
      <path d="M13.5 12.3333H14.5V13.3333H13.5V12.3333Z" fill="black" />
      <path d="M14.5 9.33334H15.5V10.3333H14.5V9.33334Z" fill="black" />
      <path d="M14.5 13.3333H15.5V14.3333H14.5V13.3333Z" fill="black" />
      <path d="M8.33337 11.3333H9.33337V12.3333H8.33337V11.3333Z" fill="black" />
      <path d="M12.5 11.3333H13.5V12.3333H12.5V11.3333Z" fill="black" />
      <path d="M17.6667 17.6667H18.6667V6.33334H17.6667V17.6667Z" fill="black" />
      <path d="M6.33337 5.33334V6.33334L17.6667 6.33334V5.33334H6.33337Z" fill="black" />
      <path d="M6.33337 6.33334H5.33337V17.6667H6.33337V6.33334Z" fill="black" />
      <path d="M6.33337 17.6667V18.6667H17.6667V17.6667H6.33337Z" fill="black" />
    </Svg>
  )
}

export default Icon
