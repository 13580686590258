import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M6.66663 17.6667V6.66675H17.6666V17.6667H6.66663Z" fill="white" />
      <path d="M14.6666 9.66675H13.6666V10.6667H14.6666V9.66675Z" />
      <path d="M14.6666 13.6667H13.6666V14.6667H14.6666V13.6667Z" />
      <path d="M13.6666 10.6667H12.6666V11.6667H13.6666V10.6667Z" />
      <path d="M13.6666 12.6667H12.6666V13.6667H13.6666V12.6667Z" />
      <path d="M10.6666 9.66675H9.66663V10.6667H10.6666V9.66675Z" />
      <path d="M10.6666 13.6667H9.66663V14.6667H10.6666V13.6667Z" />
      <path d="M9.66663 8.66675H8.66663V9.66675H9.66663V8.66675Z" />
      <path d="M15.6666 8.66675H14.6666V9.66675H15.6666V8.66675Z" />
      <path d="M11.6666 12.6667H10.6666V13.6667H11.6666V12.6667Z" />
      <path d="M11.6666 10.6667H10.6666V11.6667H11.6666V10.6667Z" />
      <path d="M12.6666 11.6667H11.6666V12.6667H12.6666V11.6667Z" />
      <path d="M9.66663 14.6667H8.66663V15.6667H9.66663V14.6667Z" />
      <path d="M15.6666 14.6667H14.6666V15.6667H15.6666V14.6667Z" />
      <path d="M17.6666 6.66675V5.66675H6.66663V6.66675L17.6666 6.66675Z" />
      <path d="M5.66663 17.6667H6.66663V6.66675H5.66663V17.6667Z" />
      <path d="M17.6666 18.6667V17.6667H6.66663V18.6667H17.6666Z" />
      <path d="M18.6666 6.66675L17.6666 6.66675V17.6667H18.6666V6.66675Z" />
    </Svg>
  )
}

export default Icon
