import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { LogoutIcon, useModal, UserMenu as UIKitUserMenu, UserMenuItem, WalletIcon } from 'uikit'
import useAuth from 'hooks/useAuth'
import ConnectWalletButton from 'components/ConnectWalletButton'
import WalletModal, { WalletView } from './WalletModal'

const UserMenu = () => {
  const { account } = useWeb3React()
  const { logout } = useAuth()

  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)

  if (!account) {
    return <ConnectWalletButton scale="md" maxWidth="180px" />
  }

  return (
    <UIKitUserMenu account={account}>
      <UserMenuItem as="button" onClick={onPresentTransactionModal}>
        <WalletIcon width={24} />
        Wallet & Transactions
      </UserMenuItem>

      <UserMenuItem as="button" onClick={logout} color="#F44545">
        <LogoutIcon width="24px" />
        Disconnect
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu
