import React from 'react'
import { Text, InfoIcon } from 'uikit'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { AutoColumn } from 'components/Layout/Column'
import { getClipPath } from 'utils/getClipPath'

const sides = {
  x: { a: '4px', b: '8px', a100: 'calc(100% - 4px)', b100: 'calc(100% - 8px)' },
  y: { long: '8px', short: '4px', long100: 'calc(100% - 8px)', short100: 'calc(100% - 4px)' },
}

export const Wrapper = styled.div`
  position: relative;
  margin-top: 23px;
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.secondary};
`

export const IconWrap = styled.span`
  display: block;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  height: 20px;
`

export const StyledBalanceMaxMini = styled.button`
  height: 20px;
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 400;
  margin-left: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 12px 20px 12px;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    opacity: 0.7;
  }
  :focus {
    outline: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.violet};
  }
`

export const TruncatedText = styled(Text).attrs({ ellipsis: true })`
  width: 220px;
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => `${theme.colors.failure}33`};
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding: 12px 20px;
  color: ${({ theme }) => theme.colors.failure};
  z-index: -1;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
  ${space}

  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

export function SwapCallbackError({ error, ...rest }: any) {
  return (
    <SwapCallbackErrorInner {...rest}>
      <InfoIcon width="24px" mr="8px" />
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => `${theme.colors.warning}33`};
  padding: 6px 15px 10px 20px;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
`
