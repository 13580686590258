import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H4H2V5V19V21H4H18H20V19V17H22V15V9V7H20V5V3H18ZM18 17V19H4V5H18V7H12H10V9V15V17H12H18ZM20 15H18H12V9H18H20V15ZM16 11H14V13H16V11Z"
      />
    </Svg>
  )
}

export default Icon
