import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="137" height="29" viewBox="0 0 137 29" fill="none" {...props}>
      <g filter="url(#a)">
        <mask id="b" width="137" height="23" x="0" y="4.16" fill="#000" maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M0 4.16h137v23H0z" />
          <path d="M3.1 22v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V7.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V22H3.11Zm16.85 0v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-8.42Zm6.31-2.1v-8.43h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V5.15h4.2v14.73h2.11V22h-4.2Zm18.95-2.1V22H43.11v-2.1H41v-8.43h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V11.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-6.31v4.21h-4.21Zm8.42-6.32v-8.42h-4.21v8.42h4.2Zm18.94.01V22H72.59v-2.1h-2.1v-8.43h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 22v-2.1h-2.1V5.15h4.2v14.73h2.1V22h-4.2Zm18.94-2.1V22H95.74v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V9.36h4.2v10.52h4.22V9.37H121V22h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1v-4.22h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V22h-10.52Z" />
        </mask>
        <path
          fill="#18203D"
          d="M3.1 22v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V7.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V22H3.11Zm16.85 0v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-8.42Zm6.31-2.1v-8.43h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V5.15h4.2v14.73h2.11V22h-4.2Zm18.95-2.1V22H43.11v-2.1H41v-8.43h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V11.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-6.31v4.21h-4.21Zm8.42-6.32v-8.42h-4.21v8.42h4.2Zm18.94.01V22H72.59v-2.1h-2.1v-8.43h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 22v-2.1h-2.1V5.15h4.2v14.73h2.1V22h-4.2Zm18.94-2.1V22H95.74v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V9.36h4.2v10.52h4.22V9.37H121V22h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1v-4.22h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V22h-10.52Z"
        />
        <path
          stroke="#18203D"
          strokeWidth="1.01"
          d="M3.1 22v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V7.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V22H3.11Zm16.85 0v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-8.42Zm6.31-2.1v-8.43h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V5.15h4.2v14.73h2.11V22h-4.2Zm18.95-2.1V22H43.11v-2.1H41v-8.43h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V11.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V22h-6.31v4.21h-4.21Zm8.42-6.32v-8.42h-4.21v8.42h4.2Zm18.94.01V22H72.59v-2.1h-2.1v-8.43h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 22v-2.1h-2.1V5.15h4.2v14.73h2.1V22h-4.2Zm18.94-2.1V22H95.74v-2.1h-2.1v-8.43h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V9.36h4.2v10.52h4.22V9.37H121V22h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1v-4.22h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V22h-10.52Z"
          mask="url(#b)"
        />
        <mask id="c" width="137" height="23" x="0" y="2.16" fill="#000" maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M0 2.16h137v23H0z" />
          <path d="M3.1 20v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V5.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V20H3.11Zm16.85 0v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-8.42Zm6.31-2.1V9.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V3.15h4.2v14.73h2.11V20h-4.2Zm18.95-2.1V20H43.11v-2.1H41V9.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V9.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-6.31v4.21h-4.21Zm8.42-6.32V9.47h-4.21v8.42h4.2Zm18.94.01V20H72.59v-2.1h-2.1V9.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 20v-2.1h-2.1V3.15h4.2v14.73h2.1V20h-4.2Zm18.94-2.1V20H95.74v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V7.36h4.2v10.52h4.22V7.37H121V20h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V20h-10.52Z" />
        </mask>
        <path
          fill="#72E3FF"
          d="M3.1 20v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V5.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V20H3.11Zm16.85 0v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-8.42Zm6.31-2.1V9.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V3.15h4.2v14.73h2.11V20h-4.2Zm18.95-2.1V20H43.11v-2.1H41V9.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V9.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-6.31v4.21h-4.21Zm8.42-6.32V9.47h-4.21v8.42h4.2Zm18.94.01V20H72.59v-2.1h-2.1V9.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 20v-2.1h-2.1V3.15h4.2v14.73h2.1V20h-4.2Zm18.94-2.1V20H95.74v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V7.36h4.2v10.52h4.22V7.37H121V20h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V20h-10.52Z"
        />
        <path
          stroke="#18203D"
          strokeWidth="1.01"
          d="M3.1 20v-2.1H1v-2.11h4.21v2.1h6.32v-6.31H3.1v-2.1H1V5.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V20H3.11Zm16.85 0v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-8.42Zm6.31-2.1V9.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V3.15h4.2v14.73h2.11V20h-4.2Zm18.95-2.1V20H43.11v-2.1H41V9.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V9.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V20h-6.31v4.21h-4.21Zm8.42-6.32V9.47h-4.21v8.42h4.2Zm18.94.01V20H72.59v-2.1h-2.1V9.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 20v-2.1h-2.1V3.15h4.2v14.73h2.1V20h-4.2Zm18.94-2.1V20H95.74v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V7.36h4.2v10.52h4.22V7.37H121V20h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V9.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V20h-10.52Z"
          mask="url(#c)"
        />
        <mask id="d" width="137" height="23" x="0" y=".16" fill="#000" maskUnits="userSpaceOnUse">
          <path fill="#fff" d="M0 .16h137v23H0z" />
          <path d="M3.1 18v-2.1H1v-2.11h4.21v2.1h6.32V9.58H3.1v-2.1H1V3.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V18H3.11Zm16.85 0v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-8.42Zm6.31-2.1V7.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V1.15h4.2v14.73h2.11V18h-4.2Zm18.95-2.1V18H43.11v-2.1H41V7.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V7.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-6.31v4.21h-4.21Zm8.42-6.32V7.47h-4.21v8.42h4.2Zm18.94.01V18H72.59v-2.1h-2.1V7.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 18v-2.1h-2.1V1.15h4.2v14.73h2.1V18h-4.2Zm18.94-2.1V18H95.74v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V5.36h4.2v10.52h4.22V5.37H121V18h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V18h-10.52Z" />
        </mask>
        <path
          fill="#fff"
          d="M3.1 18v-2.1H1v-2.11h4.21v2.1h6.32V9.58H3.1v-2.1H1V3.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V18H3.11Zm16.85 0v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-8.42Zm6.31-2.1V7.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V1.15h4.2v14.73h2.11V18h-4.2Zm18.95-2.1V18H43.11v-2.1H41V7.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V7.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-6.31v4.21h-4.21Zm8.42-6.32V7.47h-4.21v8.42h4.2Zm18.94.01V18H72.59v-2.1h-2.1V7.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 18v-2.1h-2.1V1.15h4.2v14.73h2.1V18h-4.2Zm18.94-2.1V18H95.74v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V5.36h4.2v10.52h4.22V5.37H121V18h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V18h-10.52Z"
        />
        <path
          stroke="#18203D"
          strokeWidth="1.01"
          d="M3.1 18v-2.1H1v-2.11h4.21v2.1h6.32V9.58H3.1v-2.1H1V3.25h2.1v-2.1h10.53v2.1h2.1v2.1h-4.2v-2.1H5.2v4.21h8.42v2.1h2.1v6.32h-2.1V18H3.11Zm16.85 0v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-8.42Zm6.31-2.1V7.46h-4.2v8.42h4.2Zm8.42 2.1v-2.1h-2.1V1.15h4.2v14.73h2.11V18h-4.2Zm18.95-2.1V18H43.11v-2.1H41V7.46h2.1v-2.1h8.43v2.1h2.1v4.21h-2.1v2.11H45.2v2.1h8.42Zm-4.2-4.22v-4.2H45.2v4.2h4.21Zm6.31 10.53V7.47h2.1v-2.1h8.42v2.1h2.1v8.42h-2.1V18h-6.31v4.21h-4.21Zm8.42-6.32V7.47h-4.21v8.42h4.2Zm18.94.01V18H72.59v-2.1h-2.1V7.46h2.1v-2.1H81v2.1h2.1v4.21H81v2.11h-6.32v2.1h8.43Zm-4.2-4.22v-4.2h-4.22v4.2h4.21ZM87.32 18v-2.1h-2.1V1.15h4.2v14.73h2.1V18h-4.2Zm18.94-2.1V18H95.74v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h2.1v4.21h-2.1v2.11h-6.32v2.1h8.42Zm-4.2-4.22v-4.2h-4.22v4.2h4.21Zm8.41 6.32v-2.1h-2.1V5.36h4.2v10.52h4.22V5.37H121V18h-10.53Zm12.63 0v-2.1h8.43v-2.11h-6.32v-2.1h-2.1V7.46h2.1v-2.1h8.42v2.1h-6.31v2.1h6.31v2.11h2.1v4.21h-2.1V18h-10.52Z"
          mask="url(#d)"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="135.75"
          height="27.79"
          x=".5"
          y=".65"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1.73" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_706_2502" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_706_2502" result="shape" />
        </filter>
      </defs>
    </Svg>
  )
}

export default Icon
