import React from 'react'

import { BodyWrapper, ContentWrapper } from './styled'

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return (
    <BodyWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </BodyWrapper>
  )
}
