import React, { useState } from 'react'
import { Box, CopyIcon, CheckIcon, Flex, FlexProps, IconButton } from 'uikit'
import styled from 'styled-components'
import { getClipPath } from 'utils/getClipPath'

interface CopyAddressProps extends FlexProps {
  account: string
}

const sides = {
  x: { a: '4px', b: '8px', a100: 'calc(100% - 4px)', b100: 'calc(100% - 8px)' },
  y: { long: '6px', short: '3px', long100: 'calc(100% - 6px)', short100: 'calc(100% - 3px)' },
}

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: #ebebeb;
  position: relative;
  height: 50px;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 20px;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.secondary};
    display: block;
    font-size: 16px;
    line-height: 17px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
`

const CopyAddress: React.FC<CopyAddressProps> = ({ account, ...props }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyAddress = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(account).then(() => {
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000)
      })
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = account
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    }
  }

  return (
    <Box position="relative" {...props}>
      <Wrapper>
        <Address title={account}>
          <input type="text" readOnly value={account} />
        </Address>
        <IconButton variant="text" onClick={copyAddress}>
          {isCopied ? <CheckIcon color="#392953" width="24px" /> : <CopyIcon color="#392953" width="24px" />}
        </IconButton>
      </Wrapper>
    </Box>
  )
}

export default CopyAddress
