import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { getClipPath } from 'utils/getClipPath'
import { InfoIcon } from '../Svg'
import { Flex } from '../Box'
import { Text } from '../Text'
import { MessageProps } from './types'

const sides = {
  x: { a: '4px', b: '8px', a100: 'calc(100% - 4px)', b100: 'calc(100% - 8px)' },
  y: { long: '11px', short: '5px', long100: 'calc(100% - 11px)', short100: 'calc(100% - 5px)' },
}

const MessageContainer = styled.div<MessageProps>`
  background: rgba(199, 67, 245, 0.1);
  padding: ${({ padding }) => padding || '20px'};
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
  ${space}
  display: grid;
  row-gap: 8px;
  max-width: 400px;
`

const Message: React.FC<MessageProps> = ({ children, variant, title, icon, ...props }) => {
  return (
    <MessageContainer variant={variant} {...props}>
      <Flex alignItems="center">
        {icon || <InfoIcon color="#C743F5" width="24px" />}
        {title && (
          <Text ml="8px" color="#392953">
            {title}
          </Text>
        )}
      </Flex>
      {children}
    </MessageContainer>
  )
}

export default Message
