import React from 'react'
import styled from 'styled-components'
import { Currency, Percent, Price } from '@pancakeswap/sdk'
import { Text } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { RowBetween } from 'components/Layout/Row'
import { AutoColumn } from '../../components/Layout/Column'
import { ONE_BIPS } from '../../config/constants'
import { Field } from '../../state/mint/actions'

const Wrapper = styled(AutoColumn)`
  border-top: 1px solid rgba(57, 41, 83, 0.1);
  padding-top: 16px;
  margin-top: 16px;
`

function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {
  const { t } = useTranslation()
  return (
    <Wrapper gap="8px">
      <RowBetween>
        <Text fontSize="14px" color="#392953">
          {t('%assetA% per %assetB%', {
            assetA: currencies[Field.CURRENCY_B]?.symbol ?? '',
            assetB: currencies[Field.CURRENCY_A]?.symbol ?? '',
          })}
        </Text>
        <Text>{price?.toSignificant(6) ?? '-'}</Text>
      </RowBetween>
      <RowBetween>
        <Text fontSize="14px" color="#392953">
          {t('%assetA% per %assetB%', {
            assetA: currencies[Field.CURRENCY_A]?.symbol ?? '',
            assetB: currencies[Field.CURRENCY_B]?.symbol ?? '',
          })}
        </Text>
        <Text>{price?.invert()?.toSignificant(6) ?? '-'}</Text>
      </RowBetween>
      <RowBetween>
        <Text fontSize="14px" color="#392953">
          {t('Share of Pool')}
        </Text>
        <Text>
          {noLiquidity && price
            ? '100'
            : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </Text>
      </RowBetween>
    </Wrapper>
  )
}

export default PoolPriceBar
