import React from 'react'
import styled from 'styled-components'
import useLastTruthy from 'hooks/useLast'
import { BodyWrapper, ContentWrapper } from 'components/App/styled'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled(BodyWrapper)<{ show: boolean }>`
  margin-top: 8px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-50px)')};
  transition: transform 300ms ease-in-out;
`

const Content = styled(ContentWrapper)<{ show: boolean }>`
  padding: ${({ show }) => (show ? '35px 35px 35px 34px' : '0')};
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)
  const hasTrade = Boolean(trade)
  return (
    <AdvancedDetailsFooter show={hasTrade}>
      <Content show={hasTrade}>
        <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
      </Content>
    </AdvancedDetailsFooter>
  )
}
