import React, { FC } from 'react'
import { StyledButton, ContentWrap } from './styled'

export const PrimaryButton: FC<any> = ({ scale = 'md', color = 'green', isLoading, disabled, children, ...rest }) => {
  const isDisabled = isLoading || disabled

  return (
    <StyledButton {...{ scale, color, disabled: isDisabled, ...rest }}>
      <ContentWrap {...{ scale, color }}>{children}</ContentWrap>
    </StyledButton>
  )
}
