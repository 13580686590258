import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { SubMenuItems } from '../../components/SubMenuItems'
import Logo from './components/Logo'
import { NavProps } from './types'

import bgTop from './pngs/bg_top.png'
import bgMid from './pngs/bg_mid.png'
import bgHero from './pngs/hero.svg'

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 768px;
  background: url(${bgHero}) center top no-repeat, url(${bgTop}) center top repeat-x,
    linear-gradient(#4bd6fc 0, #4bd6fc 100%) center top / 100% 128px no-repeat, url(${bgMid}) center top -28px;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 24px 80px;
  height: 100px;

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 24px 20px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px 20px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 24px 40px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 24px 80px;
  }
`

const Menu: React.FC<NavProps> = ({ userMenu, subLinks, activeSubItem, children }) => {
  return (
    <Wrapper>
      <StyledNav showMenu>
        <Logo href="/swap" />

        <Flex alignItems="center">
          <SubMenuItems items={subLinks} activeItem={activeSubItem} />
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end">
          {userMenu}
        </Flex>
      </StyledNav>

      {children}
    </Wrapper>
  )
}

export default Menu
