import React from 'react'
import { Currency, Pair } from '@pancakeswap/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex } from 'uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0;
  height: 24px;
`

const MaxButton = styled(Button)`
  background-color: rgba(199, 67, 245, 0.1);
  font-size: 12px;
  line-height: 13px;
  color: #c743f5;
  border-radius: 0;
  padding: 5px 8px 6px;
  height: 24px;
  margin-right: 16px;
`

export const inputPanelClipPath = `polygon(
  0 12.6%,
  1% 12.6%,
  1% 6%,
  2% 6%,
  2% 0,
  98% 0,
  98% 6%,
  99% 6%,
  99% 12.6%,
  100% 12.6%,
  100% 86.2%,
  99% 86.2%,
  99% 94%,
  98% 94%,
  98% 100%,
  2% 100%,
  2% 94%,
  1% 94%,
  1% 86.2%,
  0 86.2%,
  0 0
)`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  position: relative;
  background-color: #fff;
  clip-path: ${inputPanelClipPath};
  padding: 20px;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const translatedLabel = label || t('Input')

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
    />,
  )
  return (
    <InputPanel id={id}>
      {!hideInput && (
        <Text fontSize="14px" lineHeight="15px" mb="8px">
          {translatedLabel}
        </Text>
      )}
      <InputRow selected={disableCurrencySelect}>
        {!hideInput && (
          <>
            <NumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
            {account && currency && showMaxButton && label !== 'To' && (
              <MaxButton onClick={onMax} scale="sm" variant="text">
                MAX
              </MaxButton>
            )}
          </>
        )}
        <CurrencySelectButton
          selected={!!currency}
          className="open-currency-select-button"
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal()
            }
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
            ) : currency ? (
              <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
            ) : null}
            {pair ? (
              <Text id="pair">
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair">
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length,
                    )}`
                  : currency?.symbol) || 'Select a currency'}
              </Text>
            )}
            {!disableCurrencySelect && <ChevronDownIcon width="12" ml="4px" />}
          </Flex>
        </CurrencySelectButton>
      </InputRow>
    </InputPanel>
  )
}
