import React from 'react'
import { PrimaryButton, useWalletModal } from 'uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectWalletButton = ({ scale = 'lg', ...props }) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <PrimaryButton scale={scale} onClick={onPresentConnectModal} {...props}>
      Connect Wallet
    </PrimaryButton>
  )
}

export default ConnectWalletButton
