import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2H14V4H16V6H4V8H2V13H4V8H16V10H14V12H16V10H18V8H20V6H18V4H16V2ZM6 20H8V22H10V20H8V18H20V16H22V11H20V16H8V14H10V12H8V14H6V16H4V18H6V20Z"
      />
    </Svg>
  )
}

export default Icon
