import React from 'react'
import styled from 'styled-components'
import { getClipPath } from 'utils/getClipPath'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { PrimaryButton } from '../../components/Button'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  display: flex;
  padding: 40px 40px 23px;
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow: auto;
  max-height: calc(80vh - 123px);
  padding: 0 20px;
  margin: 0 20px 40px;
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <PrimaryButton color="violet" scale="sm" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="primary" width="24px" />
    </PrimaryButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <PrimaryButton color="violet" scale="sm" onClick={onBack} area-label="go back" mr="18px">
      <ArrowBackIcon color="primary" width="24px" />
    </PrimaryButton>
  )
}

const sides = {
  x: { a: '1%', b: '3%', a100: '99%', b100: '97%' },
  y: { long: '4%', short: '1.2%', long100: '96%', short100: '98.8%' },
}

const Container = styled(Box)<any>`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  max-height: 85vh;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};

  ${({ theme }) => theme.mediaQueries.xs} {
    min-width: 100vw;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: ${({ minWidth }) => minWidth};
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.modal};
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    clip-path: ${getClipPath({ sides, type: 'MODAL' })};
  }
`

export const ModalContainer = ({ children, ...props }) => (
  <Wrapper>
    <Container {...props}>{children}</Container>
  </Wrapper>
)
