import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M13 6.00001L14 6.00003L14 5.00001H13V6.00001Z" fill="black" />
      <path d="M11 6.00001L10 6.00003L10 5.00001H11V6.00001Z" fill="black" />
      <path d="M18 11H19V10L18 10L18 11Z" fill="black" />
      <path d="M6 11H5V10H6V11Z" fill="black" />
      <path d="M18 14H19L19 13L18 13V14Z" fill="black" />
      <path d="M6 14H5L5 13L6 13V14Z" fill="black" />
      <path d="M13 19L14 19L14 18L13 18L13 19Z" fill="black" />
      <path d="M11 19L10 19L10 18L11 18L11 19Z" fill="black" />
      <path d="M15 10H14V14H15V10Z" fill="black" />
      <path d="M14 10L14 9.00003H10L10 10H14Z" fill="black" />
      <path d="M10 10H9V14H10V10Z" fill="black" />
      <path d="M14 15L14 14H10L10 15H14Z" fill="black" />
      <path d="M7 17L7 18H10V17L7 17Z" fill="black" />
      <path d="M6 17H7V14L6 14L6 17Z" fill="black" />
      <path d="M5 11L4 11V13H5L5 11Z" fill="black" />
      <path d="M6 10L7 10L7 7.00003L6 7L6 10Z" fill="black" />
      <path d="M10 7.00003V6.00003H7V7.00003H10Z" fill="black" />
      <path d="M13 5.00001L13 4H11L11 5.00001H13Z" fill="black" />
      <path d="M14 6.00003V7.00003H17V6.00003H14Z" fill="black" />
      <path d="M17 10H18V7L17 7.00003L17 10Z" fill="black" />
      <path d="M20 11L19 11L19 13H20V11Z" fill="black" />
      <path d="M17 17H18L18 14L17 14V17Z" fill="black" />
      <path d="M17 18L17 17L14 17V18H17Z" fill="black" />
      <path d="M13 20V19H11V20H13Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7H10V6H11V5H13V6H14V7H17V10H18V11H19V13H18V14H17V17H14V18H13V19H11V18H10V17H7V14H6V13H5V11H6V10H7V7ZM10 9V10H9V14H10V15H14V14H15V10H14V9H10Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
