import Metamask from '../../components/Svg/Icons/Metamask'

import { Config, ConnectorNames } from './types'

const wallet: Config = {
  title: 'Metamask',
  icon: Metamask,
  connectorId: ConnectorNames.Injected,
  priority: 1,
}

export default wallet
export const connectorLocalStorageKey = 'connectorIdv2'
export const walletLocalStorageKey = 'wallet'
