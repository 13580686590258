export type MenuItemsType = { label: string; href: string }[]

export const menuLinks: MenuItemsType = [
  {
    label: 'Exchange',
    href: '/swap',
  },
  {
    label: 'Liquidity',
    href: '/liquidity',
  },
]
