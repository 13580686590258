import styled from 'styled-components'
import Text from '../Text/Text'
import { tags, HeadingProps } from './types'

const Heading = styled(Text)<HeadingProps>`
  font-size: 48px;
  line-height: 52px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  white-space: nowrap;
`

Heading.defaultProps = {
  as: tags.H2,
}

export default Heading
