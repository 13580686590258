import { Currency } from '@pancakeswap/sdk'
import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from './CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; maxWidth?: string }>`
  display: flex;
  flex-direction: row;
  margin-right: ${({ margin }) => margin && '4px'};
  max-width: ${({ maxWidth }) => maxWidth};
`

const LogoWrapper = styled.div<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: #fff;
  border-radius: 50%;
  &:last-of-type {
    margin-left: -8px;
  }
`
interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin = false,
}: DoubleCurrencyLogoProps) {
  const width = `${size.toString()}px`
  return (
    <Wrapper margin={margin} maxWidth={`${size * 1.6}px`}>
      {currency0 && (
        <LogoWrapper size={width}>
          <CurrencyLogo currency={currency0} size={width} style={{ marginRight: '4px' }} />
        </LogoWrapper>
      )}

      {currency1 && (
        <LogoWrapper size={width}>
          <CurrencyLogo currency={currency1} size={width} />
        </LogoWrapper>
      )}
    </Wrapper>
  )
}
