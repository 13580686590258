import React from 'react'
import { HistoryIcon, PrimaryButton, useModal } from 'uikit'
import TransactionsModal from './TransactionsModal'

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <PrimaryButton color="violet" scale="sm" onClick={onPresentTransactionsModal}>
      <HistoryIcon width="24px" />
    </PrimaryButton>
  )
}

export default Transactions
