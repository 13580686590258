import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4L18 20H20L20 4H18ZM4 11V13L12 13V15H10V17H12L12 15H14V13H16V11H14V9H12V7H10V9H12V11L4 11Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default Icon
