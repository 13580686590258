export const triggerClipPath = `polygon(
  98% 0%,
  98% 6%,
  99% 6%,
  99% 12.64%,
  100% 12.64%,
  100% 87.36%,
  99% 87.36%,
  99% 94%,
  98% 94%,
  98% 100%,
  2% 100%,
  2% 94%,
  1% 94%,
  1% 87.36%,
  0% 87.36%,
  0% 12.64%,
  1% 12.64%,
  1% 6%,
  2% 6%,
  2% 0%
)`

export const menuClipPath = `polygon(
  98% 0%,
  98% 6.5%,
  99% 6.5%,
  99% 12.64%,
  100% 12.64%,
  100% 86.2%,
  99% 86.2%,
  99% 94%,
  98% 94%,
  98% 100%,
  2% 100%,
  2% 94%,
  1% 94%,
  1% 86.2%,
  0% 86.2%,
  0% 12.64%,
  1% 12.64%,
  1% 6.5%,
  2% 6.5%,
  2% 0%
)`
