import React, { useCallback } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import styled from 'styled-components'
import {
  Text,
  InfoIcon,
  PrimaryButton,
  CheckIcon,
  Flex,
  Box,
  LinkExternal,
  Spinner,
  Modal,
  InjectedModalProps,
} from 'uikit'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
`

const SpinnerWrapper = styled(ColumnCenter)`
  display: grid;
  place-content: center;
  margin: 0 auto 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  border-radius: 50%;
  background-color: rgba(38, 204, 35, 0.1);
  width: 119px;
  height: 119px;
  display: grid;
  place-content: center;
  margin: 0 auto 24px;
`

function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <SpinnerWrapper>
        <Spinner color="#69defd" size={100} />
      </SpinnerWrapper>
      <AutoColumn gap="12px" justify="center">
        <Text fontSize="20px">{t('Waiting For Confirmation')}</Text>
        <AutoColumn gap="4px" justify="center">
          <Text textAlign="center">{pendingText}</Text>
          <Text textAlign="center">{t('Confirm this transaction in your wallet')}</Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ConfirmedIcon>
        <CheckIcon width="44px" color="#26CC23" />
      </ConfirmedIcon>
      <AutoColumn gap="8px" justify="center">
        {chainId && hash && (
          <Flex alignItems="center" justifyContent="center">
            <LinkExternal color="#392953" href={getBscScanLink(hash, 'transaction', chainId)}>
              {t('View on BscScan')}
            </LinkExternal>
          </Flex>
        )}
        {/* {currencyToAdd && library?.provider?.isMetaMask && (
          <PrimaryButton
            scale="lg"
            color="orange"
            onClick={() => registerToken(token.address, token.symbol, token.decimals)}
          >
            {t('Add %asset% to Metamask', { asset: currencyToAdd.symbol })}
          </PrimaryButton>
        )} */}
        <PrimaryButton scale="lg" color="red" onClick={onDismiss}>
          Close
        </PrimaryButton>
      </AutoColumn>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  return (
    <Wrapper>
      <AutoColumn justify="center">
        <InfoIcon color="#F44545" width="64px" />
        <Text color="failure" style={{ textAlign: 'center', width: '302px' }}>
          {message}
        </Text>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px">
        <PrimaryButton scale="lg" color="red" onClick={onDismiss}>
          Dismiss
        </PrimaryButton>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} onDismiss={handleDismiss} minWidth="382px">
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
