import styled from 'styled-components'
import { getClipPath } from 'utils/getClipPath'

const sides = {
  x: { a: '2px', b: '4px', a100: 'calc(100% - 2px)', b100: 'calc(100% - 4px)' },
  y: { long: '8px', short: '4px', long100: 'calc(100% - 8px)', short100: 'calc(100% - 4px)' },
}

export const StyledTooltip = styled.div`
  padding: 12px;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;

  max-width: 215px;
  z-index: 101;
  background: rgba(0, 0, 0, 0.8);
  top: 50%;
  transform: translateY(-50%);
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
`
