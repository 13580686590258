import React from 'react'
import { ModalBody, ModalHeader, ModalTitle, ModalContainer, ModalCloseButton, ModalBackButton } from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  minWidth = '382px',
}) => (
  <ModalContainer minWidth={minWidth}>
    <ModalHeader>
      <ModalTitle>
        {onBack && <ModalBackButton onBack={onBack} />}
        {title}
      </ModalTitle>
      {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
    </ModalHeader>
    <ModalBody>{children}</ModalBody>
  </ModalContainer>
)

export default Modal
