import React from 'react'
import styled from 'styled-components'
import { Currency, Price } from '@pancakeswap/sdk'
import { Text, AutoRenewIcon } from 'uikit'
import { CurrencyLogo } from 'components/Logo'
import { StyledBalanceMaxMini, IconWrap } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
  inputCurrency: Currency
  outputCurrency: Currency
  fontSize?: string
}

const Label = styled(Text)`
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.secondary};
`

export default function TradePrice({
  price,
  showInverted,
  setShowInverted,
  inputCurrency,
  outputCurrency,
  fontSize,
}: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <Label fontSize={fontSize || '16px'}>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <CurrencyLogo currency={showInverted ? outputCurrency : inputCurrency} size="12px" />
            <IconWrap>
              <AutoRenewIcon width="12px" height="12px" />
            </IconWrap>
            <CurrencyLogo currency={showInverted ? inputCurrency : outputCurrency} size="12px" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Label>
  )
}
