import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Toggle, Flex, Modal, InjectedModalProps } from 'uikit'
import { useExpertModeManager, useUserExpertModeAcknowledgementShow, useUserSingleHopOnly } from 'state/user/hooks'
import { useSwapActionHandlers } from 'state/swap/hooks'
import QuestionHelper from '../../QuestionHelper'
import TransactionSettings from './TransactionSettings'
import ExpertModal from './ExpertModal'
import GasSettings from './GasSettings'

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 196px;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
`

const SettingsModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgementShow()
  const [expertMode, setExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const { onChangeRecipient } = useSwapActionHandlers()

  const handleExpertModeToggle = (newExpertMode) => {
    if (!newExpertMode) {
      setExpertMode(false)
      onChangeRecipient(null)
    } else if (showExpertModeAcknowledgement) {
      setShowConfirmExpertModal(true)
    } else {
      setExpertMode(true)
      onChangeRecipient(null)
    }
  }

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    )
  }

  return (
    <Modal title="Settings" headerBackground="gradients.cardHeader" onDismiss={onDismiss} minWidth="480px">
      <Flex pb="24px" flexDirection="column">
        <Text fontSize="12px" color="#392953" mb="16px">
          Global
        </Text>
        <GasSettings />
      </Flex>
      <Flex flexDirection="column">
        <Text fontSize="12px" color="#392953" mb="16px">
          Swaps & Liquidity
        </Text>
        <TransactionSettings />
      </Flex>

      <ToggleWrapper>
        <Flex alignItems="center">
          <Text>Expert Mode</Text>
          <QuestionHelper
            text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk."
            placement="top-start"
            ml="4px"
          />
        </Flex>
        <Toggle id="toggle-expert-mode-button" checked={expertMode} handleSelect={handleExpertModeToggle} />
      </ToggleWrapper>

      <ToggleWrapper>
        <Flex alignItems="center">
          <Text>Disable Multihops</Text>
          <QuestionHelper text="Restricts swaps to direct pairs only." placement="top-start" ml="4px" />
        </Flex>
        <Toggle id="toggle-disable-multihop-button" checked={singleHopOnly} handleSelect={setSingleHopOnly} />
      </ToggleWrapper>
    </Modal>
  )
}

export default SettingsModal
