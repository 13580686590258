import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Trade, TradeType } from '@pancakeswap/sdk'
import { PrimaryButton, Text, Flex, InfoIcon, ArrowDownIcon } from 'uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { getClipPath } from 'utils/getClipPath'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'

const sides = {
  x: { a: '4px', b: '8px', a100: 'calc(100% - 4px)', b100: 'calc(100% - 8px)' },
  y: { long: '6px', short: '3px', long100: 'calc(100% - 6px)', short100: 'calc(100% - 3px)' },
}

const CurrencyRowWrap = styled.div`
  padding: 12px 20px;
  background-color: #ebebeb;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};
  display: flex;
  justify-content: space-between;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: Trade
  allowedSlippage: number
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  )
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  return (
    <AutoColumn gap="md">
      <CurrencyRowWrap>
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.inputAmount.currency} size="24px" style={{ marginRight: '8px' }} />
          <TruncatedText color="#392953">{trade.inputAmount.toSignificant(6)}</TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="20px" ml="10px">
            {trade.inputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </CurrencyRowWrap>
      <Flex justifyContent="center">
        <ArrowDownIcon width="24px" />
      </Flex>
      <CurrencyRowWrap>
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.outputAmount.currency} size="24px" style={{ marginRight: '8px' }} />
          <TruncatedText
            color={
              priceImpactSeverity > 2
                ? 'failure'
                : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                ? 'primary'
                : 'text'
            }
          >
            {trade.outputAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="20px" ml="10px">
            {trade.outputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </CurrencyRowWrap>
      {showAcceptChanges && (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <RowFixed>
              <InfoIcon width="24px" mr="8px" />
              <Text>Price Updated</Text>
            </RowFixed>
            <PrimaryButton color="red" maxWidth="120px" onClick={onAcceptChanges}>
              Accept
            </PrimaryButton>
          </RowBetween>
        </SwapShowAcceptChanges>
      )}
      <AutoColumn
        justify="flex-start"
        gap="sm"
        style={{ padding: '2px 0 16px', borderBottom: '1px solid #EBEBEB', maxWidth: '400px' }}
      >
        <Text fontSize="14px" color="#392953">
          {estimatedText}
          <Text as="span" color="#26CC23">
            {amount} {symbol}
          </Text>
          {transactionRevertText}
        </Text>
      </AutoColumn>
    </AutoColumn>
  )
}
