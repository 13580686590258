import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { ChevronDownIcon, WalletIcon } from '../../../../components/Svg'
import isTouchDevice from '../../../../util/isTouchDevice'
import { UserMenuProps } from './types'

import { triggerClipPath, menuClipPath } from './constants'

const TriggerWrap = styled.div`
  position: relative;
  z-index: 1;
`

const TriggerBg = styled.div`
  clip-path: ${triggerClipPath};
  position: absolute;
  z-index: -1;
  top: 4px;
  left: 4px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

export const Trigger = styled.div`
  clip-path: ${triggerClipPath};
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
  width: 222px;
  padding: 12px;
`

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`

const MenuWrap = styled.div<{ isOpen: boolean }>`
  z-index: 3;
  pointer-events: auto;
  visibility: visible;
  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`

const MenuBg = styled.div`
  clip-path: ${menuClipPath};
  position: absolute;
  z-index: -1;
  top: 4px;
  left: 4px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  width: 222px;
`

const Menu = styled.div`
  clip-path: ${menuClipPath};
  background-color: #ffffff;
  padding: 12px 0;
  width: 222px;
`

const WalletInfoWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  width: 100%;
  column-gap: 8px;
`

const UserMenu: React.FC<UserMenuProps> = ({ account, text, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [rotation, setRotation] = useState('rotate(0deg)')
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const hideTimeout = useRef<number>()
  const isHoveringOverTooltip = useRef(false)
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
  })

  /**
   * See "useTooltip"
   */
  useEffect(() => {
    const showTooltip = (evt: MouseEvent | TouchEvent) => {
      setIsOpen(true)
      setRotation('rotate(180deg)')

      if (evt.target === targetRef) {
        clearTimeout(hideTimeout.current)
      }

      if (evt.target === tooltipRef) {
        isHoveringOverTooltip.current = true
      }
    }

    const hideTooltip = (evt: MouseEvent | TouchEvent) => {
      if (hideTimeout.current) {
        window.clearTimeout(hideTimeout.current)
      }

      if (evt.target === tooltipRef) {
        isHoveringOverTooltip.current = false
      }

      if (!isHoveringOverTooltip.current) {
        hideTimeout.current = window.setTimeout(() => {
          if (!isHoveringOverTooltip.current) {
            setIsOpen(false)
            setRotation('rotate(0deg)')
          }
        }, 150)
      }
    }

    const toggleTouch = (evt: TouchEvent) => {
      const target = evt.target as Node
      const isTouchingTargetRef = target && targetRef?.contains(target)
      const isTouchingTooltipRef = target && tooltipRef?.contains(target)

      if (isTouchingTargetRef) {
        setIsOpen((prevOpen) => !prevOpen)
      } else if (isTouchingTooltipRef) {
        // Don't close the menu immediately so it catches the event
        setTimeout(() => {
          setIsOpen(false)
        }, 500)
      } else {
        setIsOpen(false)
      }
    }

    if (isTouchDevice()) {
      document.addEventListener('touchstart', toggleTouch)
    } else {
      targetRef?.addEventListener('mouseenter', showTooltip)
      targetRef?.addEventListener('mouseleave', hideTooltip)
      tooltipRef?.addEventListener('mouseenter', showTooltip)
      tooltipRef?.addEventListener('mouseleave', hideTooltip)
    }

    return () => {
      if (isTouchDevice()) {
        document.removeEventListener('touchstart', toggleTouch)
      } else {
        targetRef?.removeEventListener('mouseenter', showTooltip)
        targetRef?.removeEventListener('mouseleave', hideTooltip)
        tooltipRef?.removeEventListener('mouseenter', showTooltip)
        tooltipRef?.removeEventListener('mouseleave', hideTooltip)
      }
    }
  }, [targetRef, tooltipRef, hideTimeout, isHoveringOverTooltip, setIsOpen])

  return (
    <>
      <TriggerWrap>
        <Trigger ref={setTargetRef} {...props}>
          <WalletInfoWrapper>
            <WalletIcon color="#C743F5" width="24px" />
            <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
          </WalletInfoWrapper>
          <ChevronDownIcon style={{ transform: rotation }} color="#000000" width="12px" />
        </Trigger>
        <TriggerBg />
      </TriggerWrap>

      <MenuWrap style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        <Menu>{children}</Menu>
        <MenuBg />
      </MenuWrap>
    </>
  )
}

export default UserMenu
