import React from 'react'
import styled from 'styled-components'
import MenuItem from '../MenuItem/MenuItem'
import { SubMenuItemsProps } from './types'

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 106px;

  ${({ theme }) => theme.mediaQueries.xs} {
    column-gap: 60px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    column-gap: 60px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    column-gap: 106px;
  }
`

const SubMenuItems: React.FC<SubMenuItemsProps> = ({ items = [], activeItem }) => {
  return (
    <Wrapper>
      {items.map(
        ({ label, href }) =>
          label && (
            <MenuItem key={label} href={href} isActive={href === activeItem} variant="subMenu">
              {label}
            </MenuItem>
          ),
      )}
    </Wrapper>
  )
}

export default SubMenuItems
