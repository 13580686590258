import { darkColors, lightColors } from '../../theme/colors'
import { shadows } from '../../theme/base'
import { CardTheme } from './types'

export const light: CardTheme = {
  boxShadow: shadows.level1,
  boxShadowActive: shadows.active,
  boxShadowSuccess: shadows.success,
  boxShadowWarning: shadows.warning,
  cardHeaderBackground: {
    default: lightColors.gradients.cardHeader,
    blue: lightColors.gradients.blue,
    bubblegum: lightColors.gradients.bubblegum,
    violet: lightColors.gradients.violet,
  },
  dropShadow: 'drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))',

  background: '#EADCFF',
  lightBorder: '#FEFEFE',
  darkBorder: '#2C0101',
  violetBorder: '#856AAE',
  shadowColor: 'rgba(40, 40, 40, 0.25)',
}

export const dark: CardTheme = {
  boxShadow: shadows.level1,
  boxShadowActive: shadows.active,
  boxShadowSuccess: shadows.success,
  boxShadowWarning: shadows.warning,
  cardHeaderBackground: {
    default: darkColors.gradients.cardHeader,
    blue: darkColors.gradients.blue,
    bubblegum: lightColors.gradients.bubblegum,
    violet: darkColors.gradients.violet,
  },
  dropShadow: 'drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))',
  background: '#EADCFF',
  lightBorder: '#FEFEFE',
  darkBorder: '#2C0101',
  violetBorder: '#856AAE',
  shadowColor: 'rgba(40, 40, 40, 0.25)',
}
