import React from 'react'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard from './WalletCard'
import wallet from './config'
import { Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  return (
    <ModalContainer minWidth="382px">
      <ModalHeader>
        <ModalTitle>Connect Wallet</ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody>
        <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
      </ModalBody>
    </ModalContainer>
  )
}

export default ConnectModal
