import React from 'react'
import { Flex, PrimaryButton, Text } from 'uikit'
import { Grid } from 'components/Layout/Grid'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { GAS_PRICE_GWEI } from 'state/user/hooks/helpers'
import { useGasPriceManager } from 'state/user/hooks'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column">
      <Flex mb="8px" alignItems="center">
        <Text color="#000000">Default Transaction Speed (GWEI)</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="right-end"
          ml="4px"
        />
      </Flex>
      <Grid gap="16px">
        <PrimaryButton
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          isActive={gasPrice === GAS_PRICE_GWEI.default}
        >
          Standard
        </PrimaryButton>

        <PrimaryButton
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          isActive={gasPrice === GAS_PRICE_GWEI.fast}
        >
          Fast
        </PrimaryButton>

        <PrimaryButton
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          isActive={gasPrice === GAS_PRICE_GWEI.instant}
        >
          Instant
        </PrimaryButton>
      </Grid>
    </Flex>
  )
}

export default GasSettings
