import React from 'react'
import styled from 'styled-components'
import { SpinnerProps } from './types'

const Container = styled.div`
  position: relative;
`

const SpinnerIcon = styled.div<{ size?: number }>`
  border: ${({ size, color }) => `${Math.ceil(size * 0.06)}px solid ${color}`};
  border-top-color: transparent;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  -webkit-animation: spin 1000ms linear infinite;
  animation: spin 1000ms linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Spinner: React.FC<SpinnerProps> = ({ size = 128, color = '#c743f5' }) => {
  return (
    <Container>
      <SpinnerIcon {...{ color, size }} />
    </Container>
  )
}

export default Spinner
