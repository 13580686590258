import styled, { DefaultTheme } from 'styled-components'
import { getClipPath } from 'utils/getClipPath'
import { InputProps, scales } from './types'

interface StyledInputProps extends InputProps {
  theme: DefaultTheme
}

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return '32px'
    case scales.LG:
      return '48px'
    case scales.MD:
    default:
      return '40px'
  }
}

const sides = {
  x: { a: '4px', b: '8px', a100: 'calc(100% - 4px)', b100: 'calc(100% - 8px)' },
  y: { long: '6px', short: '3px', long100: 'calc(100% - 6px)', short100: 'calc(100% - 3px)' },
}

const Input = styled.input<InputProps>`
  background-color: ${({ theme }) => theme.colors.input};
  border: none;
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-size: 16px;
  height: ${getHeight};
  outline: 0;
  padding: 17px 20px 16px;
  width: 100%;
  clip-path: ${getClipPath({ sides, type: 'MODAL' })};

  &::placeholder {
    color: #b4b4b4;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    outline: none;
  }
`

Input.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false,
}

export default Input
