import React from 'react'
import { PrimaryButton } from '../../components/Button/PrimaryButton'
import { connectorLocalStorageKey, walletLocalStorageKey } from './config'
import { Login, Config, ConnectorNames } from './types'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => (
  <PrimaryButton
    color="orange"
    scale="lg"
    onClick={() => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

      // Since iOS does not support Trust Wallet we fall back to WalletConnect
      if (walletConfig.title === 'Trust Wallet' && isIOS) {
        login(ConnectorNames.WalletConnect)
      } else {
        login(walletConfig.connectorId)
      }

      localStorage.setItem(walletLocalStorageKey, walletConfig.title)
      localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
      onDismiss()
    }}
    id="wallet-connect-metamask"
  >
    Connect MetaMask
  </PrimaryButton>
)

export default WalletCard
