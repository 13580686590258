import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2H3H17H19V18H17H3H1V2ZM17 16V4H3V16H17ZM5 20H21V6H23V20V22H5V20Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default Icon
