import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Flex from '../../../components/Box/Flex'
import { LogoIcon } from '../../../components/Svg'

interface Props {
  href: string
}

const StyledLink = styled(Link)<any>`
  display: flex;
  align-items: center;
`

const Logo: React.FC<Props> = ({ href }) => {
  const isAbsoluteUrl = href.startsWith('http')
  const innerLogo = <LogoIcon />

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Solepeleus swap">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink to={href} aria-label="Solepeleus swap">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  )
}

export default React.memo(Logo)
