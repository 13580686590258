import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, PrimaryButton, ArrowBackIcon } from 'uikit'
import { Link } from 'react-router-dom'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const Column = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  return (
    <AppHeaderContainer>
      <Flex mr={noConfig ? 0 : '16px'}>
        {backTo && (
          <PrimaryButton color="violet" scale="sm" as={Link} to={backTo} mr="18px">
            <ArrowBackIcon width="32px" />
          </PrimaryButton>
        )}
        <Flex flexDirection="column">
          <Heading as="h2">{title}</Heading>
          <Flex alignItems="center">
            <Text fontSize="14px" lineHeight="15px">
              {subtitle}
            </Text>
            {helper && <QuestionHelper text={helper} ml="5px" placement="top-start" />}
          </Flex>
        </Flex>
      </Flex>
      {!noConfig && (
        <Column>
          <Transactions />
          <GlobalSettings />
        </Column>
      )}
    </AppHeaderContainer>
  )
}

export default AppHeader
