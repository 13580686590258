import React from 'react'
import { Flex, PrimaryButton, CogIcon, useModal } from 'uikit'
import SettingsModal from './SettingsModal'

const GlobalSettings = () => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      <PrimaryButton color="violet" scale="sm" onClick={onPresentSettingsModal} id="open-settings-dialog-button">
        <CogIcon height={24} width={24} />
      </PrimaryButton>
    </Flex>
  )
}

export default GlobalSettings
